import { WebAuthnError } from "@simplewebauthn/browser";
import { AsyncData, Deferred, Future, Lazy, Option, Result } from "@swan-io/boxed";
import { isNullish } from "@swan-io/lake/src/utils/nullish";
import { createSRPClient } from "@swan-io/srp";
import { SignJWT } from "jose";
import { atom, useAtom } from "react-atomic-state";
import { P, match } from "ts-pattern";
import { DetailedConsentPurpose, EnvType } from "../graphql/admin";
import { clear, get, open, set } from "./database";
import { epoch } from "./epoch";
import { t } from "./i18n";
import { logFrontendError } from "./logger";

const AUTHENTICATOR_ID_STORAGE_KEY = "authenticatorId";

export type SignMachine = {
  authenticatorId: string;
  privateKey: CryptoKey;
  headers: Record<string, string>;
};

export const srp = createSRPClient("SHA-256", 4096);

export const isWebAuthnCancelledError = (error: unknown) =>
  error instanceof WebAuthnError &&
  error.code === "ERROR_PASSTHROUGH_SEE_CAUSE_PROPERTY" &&
  error.name === "NotAllowedError";

export const getWebAuthnErrorText = (error: Error): string => {
  if (!(error instanceof WebAuthnError)) {
    return t("error.biometry.noCode");
  }
  // https://github.com/MasterKale/SimpleWebAuthn/blob/8447417ee277d9e049f10555477ef6b47bd65c2b/packages/browser/src/helpers/identifyAuthenticationError.ts#L29
  if (error.code === "ERROR_PASSTHROUGH_SEE_CAUSE_PROPERTY") {
    return t("error.biometry.notAllowed");
  }

  const code = match(error.code)
    .with("ERROR_AUTHENTICATOR_GENERAL_ERROR", () => 1)
    .with("ERROR_AUTHENTICATOR_MISSING_DISCOVERABLE_CREDENTIAL_SUPPORT", () => 2)
    .with("ERROR_AUTHENTICATOR_MISSING_USER_VERIFICATION_SUPPORT", () => 3)
    .with("ERROR_AUTHENTICATOR_NO_SUPPORTED_PUBKEYCREDPARAMS_ALG", () => 4)
    .with("ERROR_AUTHENTICATOR_PREVIOUSLY_REGISTERED", () => 5)
    .with("ERROR_CEREMONY_ABORTED", () => 6)
    .with("ERROR_INVALID_DOMAIN", () => 7)
    .with("ERROR_INVALID_RP_ID", () => 8)
    .with("ERROR_INVALID_USER_ID_LENGTH", () => 9)
    .with("ERROR_MALFORMED_PUBKEYCREDPARAMS", () => 10)
    .exhaustive();

  return t("error.biometry.generic", { code });
};

export const isWebAuthnUsable = Lazy((): Future<boolean> => {
  const isContextSecure = Future.value(
    Result.fromExecution<boolean, Error>(() => {
      return window.isSecureContext;
    }),
  );

  const isBrowserDecent =
    navigator.brave != null && typeof navigator.brave.isBrave === "function"
      ? Future.fromPromise<boolean, Error>(navigator.brave.isBrave().then(isBrave => !isBrave))
      : Future.value(Result.Ok(true));

  const browserSupportsWebAuthn = Future.make<Result<boolean, Error>>(resolve => {
    if (typeof window.PublicKeyCredential !== "function") {
      resolve(Result.Ok(false));
    } else {
      Future.fromPromise<boolean, Error>(
        window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable(),
      ).onResolve(resolve);
      setTimeout(() => resolve(Result.Ok(false)), 1000);
    }
  });

  return Future.all([isContextSecure, isBrowserDecent, browserSupportsWebAuthn])
    .map(Result.all)
    .mapOk(checks => checks.every(check => check === true))
    .tapError(error => logFrontendError(error, { tags: { context: "isWebAuthnUsable" } }))
    .map(result => result.getOr(false));
});

const signMachine = atom<AsyncData<Option<SignMachine>>>(AsyncData.NotAsked());

const [future, resolve] = Deferred.make<Option<SignMachine>>();

const DATABASE_NAME = "authenticators";
const DATABASE_VERSION = 1;
const STORE_NAME = "keys";

const db = open({
  databaseName: DATABASE_NAME,
  databaseVersion: DATABASE_VERSION,
  storeName: STORE_NAME,
});

const tryLegacySignMachine = () => {
  return open({
    databaseName: "swan",
    storeName: "signMachine",
  })
    .flatMapOk(db =>
      Future.allFromDict({
        authenticatorId: get(db, "signMachine", "authenticatorId"),
        privateKey: get(db, "signMachine", "privateKey"),
      })
        .map(Result.allFromDict)
        .mapOk(values => ({ ...values, db })),
    )
    .mapOkToResult(({ authenticatorId, privateKey, db }) => {
      return match({ authenticatorId, privateKey, db })
        .with(
          { authenticatorId: P.string, privateKey: P.instanceOf(CryptoKey) },
          ({ authenticatorId, privateKey }) => Result.Ok({ authenticatorId, privateKey, db }),
        )
        .otherwise(() => Result.Error(undefined));
    })
    .flatMapOk(({ authenticatorId, privateKey, db }) =>
      signJwt({ authenticatorId, privateKey }, {}).mapOk(token => ({
        authenticatorId,
        privateKey,
        headers: { "x-swan-authenticator-token": `Bearer ${token}` },
        db,
      })),
    )
    .tapOk(({ db, ...values }) => {
      // Move sign machine to new storage
      saveSignMachine(values);
      resolve(Option.Some(values));
      // Try to clear old database, doesn't matter if it fails
      clear(db, "signMachine");
    })
    .tapError(() => {
      signMachine.set(AsyncData.Done(Option.None()));
      resolve(Option.None());
    })
    .map(result => result.toOption());
};

export const getSignMachine = (): Future<Option<SignMachine>> => {
  const value = signMachine.get();
  if (value.isDone()) {
    return Future.value(value.get());
  }
  if (value.isLoading()) {
    return future;
  }
  signMachine.set(AsyncData.Loading());

  const possiblyAuthenticatorId = Result.fromExecution(() =>
    localStorage.getItem(AUTHENTICATOR_ID_STORAGE_KEY),
  )
    .toOption()
    .flatMap(Option.fromNullable);

  if (possiblyAuthenticatorId.isNone()) {
    return tryLegacySignMachine();
  }

  // We store the `authenticatorId` in local storage, which is stable
  const authenticatorId = possiblyAuthenticatorId.get();

  return (
    db
      // In the database, the private key for a given authenticator is stored in IndexedDB with
      // `authenticatorId` as its key, to make sure that we never have a desync between
      // the two.
      // If any of the two storages is corrupted, we gracefully fail to the enroll flow.
      .flatMapOk(db => get(db, STORE_NAME, authenticatorId))
      .mapOkToResult(value =>
        value instanceof CryptoKey
          ? Result.Ok(value)
          : Result.Error(new Error("privateKey is not a CryptoKey")),
      )
      .tapError(error => logFrontendError(error, { level: "warning" }))
      .mapOk(privateKey => ({ authenticatorId, privateKey }))
      .flatMapOk(({ authenticatorId, privateKey }) =>
        signJwt({ authenticatorId, privateKey }, {}).mapOk(token => ({
          authenticatorId,
          privateKey,
          headers: { "x-swan-authenticator-token": `Bearer ${token}` },
        })),
      )
      .map(result => {
        const value = result.toOption();
        signMachine.set(AsyncData.Done(value));
        resolve(value);
        return value;
      })
  );
};

export const saveSignMachine = ({
  authenticatorId,
  privateKey,
}: {
  authenticatorId: string;
  privateKey: CryptoKey;
}) => {
  return signJwt({ authenticatorId, privateKey }, {})
    .mapOk(token => ({
      authenticatorId,
      privateKey,
      headers: { "x-swan-authenticator-token": `Bearer ${token}` },
    }))
    .tapOk(values => signMachine.set(AsyncData.Done(Option.Some(values))))
    .flatMapOk(() =>
      db
        .flatMapOk(db => clear(db, STORE_NAME).mapOk(() => db))
        .flatMapOk(db => set(db, STORE_NAME, authenticatorId, privateKey))
        .mapOkToResult(() => {
          return Result.fromExecution<void, Error>(() =>
            localStorage.setItem(AUTHENTICATOR_ID_STORAGE_KEY, authenticatorId),
          );
        })
        .tapError(error => {
          logFrontendError(error, { level: "warning" });
        }),
    );
};

// start getting signMachine as early as possible
getSignMachine();

export const useSignMachine = () => {
  return useAtom(signMachine);
};

export const clearSignMachine = (): Future<Result<unknown, Error>> => {
  Result.fromExecution(() => localStorage.removeItem(AUTHENTICATOR_ID_STORAGE_KEY));

  return db
    .flatMapOk(db => clear(db, STORE_NAME))
    .tapError(error => logFrontendError(error, { level: "warning" }))
    .tap(() => signMachine.set(AsyncData.Done(Option.None())));
};

export const generateKeyPair = () => {
  return Future.fromPromise(
    window.crypto.subtle.generateKey({ name: "ECDSA", namedCurve: "P-256" }, false, [
      "sign",
      "verify",
    ]),
  )
    .mapOkToResult(({ privateKey, publicKey }) => {
      if (isNullish(privateKey) || isNullish(publicKey)) {
        return Result.Error(new Error("Cannot generate ECDSA key pair"));
      }
      return Result.Ok({ privateKey, publicKey });
    })
    .flatMapOk(({ privateKey, publicKey }) => {
      return Future.fromPromise(window.crypto.subtle.exportKey("jwk", publicKey)).mapOk(
        publicKeyJwk => ({
          privateKey,
          publicKeyJwk,
        }),
      );
    });
};

export const generateSaltAndVerifier = (password: string) => {
  const salt = srp.generateSalt();
  return Future.fromPromise(srp.deriveSafePrivateKey(salt, password)).mapOk(privateKey => ({
    salt,
    verifier: srp.deriveVerifier(privateKey),
  }));
};

export type ConsentForJWT = {
  env: EnvType;
  id: string;
  operationsSHA256HexDigest?: string;
  purpose: DetailedConsentPurpose;
};

export type JWTPayload = {
  consent?: ConsentForJWT;
  // Allowed with SRP
  proof?: string;
  proofContextId?: string;
};

export const signJwt = (
  { authenticatorId, privateKey }: { authenticatorId: string; privateKey: CryptoKey },
  payload: JWTPayload,
) =>
  Future.fromPromise<string, Error>(
    new SignJWT(payload)
      .setProtectedHeader({ alg: "ES256", typ: "JWT" })
      .setIssuedAt(epoch())
      .setSubject(authenticatorId)
      .sign(privateKey),
  );
