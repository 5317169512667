export const env = {
  VERSION: __env.VERSION,
  ADMIN_AUTH_API_URL: __env.ADMIN_AUTH_API_URL,
  E2E_TEST_KEY_HASH: __env.CLIENT_E2E_TEST_KEY_HASH,
  HCAPTCHA_SITE_KEY: __env.CLIENT_HCAPTCHA_SITE_KEY,
  IDEMIA_SMART_PIN_SCRIPT_URL: __env.CLIENT_IDEMIA_SMART_PIN_SCRIPT_URL,
  IDENTITY_URL: __env.IDENTITY_URL,
  PUSHER_KEY: __env.CLIENT_PUSHER_KEY,
  PUBLIC_EXPOSED_INTERNAL_LIVE_FEDERATION_URL: __env.PUBLIC_EXPOSED_INTERNAL_LIVE_FEDERATION_URL,
  PUBLIC_EXPOSED_INTERNAL_SANDBOX_FEDERATION_URL:
    __env.PUBLIC_EXPOSED_INTERNAL_SANDBOX_FEDERATION_URL,
  PSD2_SERVER_LIVE_URL: __env.CLIENT_PSD2_SERVER_LIVE_URL,
  PSD2_SERVER_SANDBOX_URL: __env.CLIENT_PSD2_SERVER_SANDBOX_URL,
  CHOOSE_PIN_ROOT_URL: __env.CHOOSE_PIN_ROOT_URL,
  TGGL_API_KEY: __env.TGGL_API_KEY,
  OAUTH_SERVER_URL: __env.OAUTH_SERVER_URL,
  SEON: __env.SEON,
};
